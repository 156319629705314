import React from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import "./whitepaper-loading.css";

const WhitepaperLoading = (props) => {
  return (
    <div className="whitepaper-loading-container1">
      <Helmet>
        <title>OfficeX Whitepaper</title>
        <meta
          name="description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. For Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX Whitepaper" />
        <meta
          property="og:description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. Made for Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <h1 className="whitepaper-loading-text1">
        <span>OfficeX</span>
        <br></br>
        <span>Whitepaper</span>
        <br></br>
      </h1>
      <a
        href="https://drive.officex.app/gift?id=9b9a8bd7-8ec1-4859-9c23-e22b1c807813&ref="
        style={{ color: "blue", textDecoration: "underline" }}
      >
        <h2 className="whitepaper-loading-text6">View Whitepaper</h2>
      </a>
    </div>
  );
};

export default WhitepaperLoading;
