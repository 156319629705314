import React from "react";
import { Link } from "react-router-dom";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Check from "../components/check";
import Line from "../components/line";
import IconContainer from "../components/icon-container";
import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>OfficeX App</title>
        <meta
          name="description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. For Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX App" />
        <meta
          property="og:description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. Made for Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section className="home-hero">
        <header className="home-header10">
          <header data-thq="thq-navbar" className="home-navbar">
            <a
              href="https://officex.app"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link10"
            >
              <img
                alt="image"
                src="/officex%20(4)-1500h.png"
                className="home-branding1"
              />
            </a>
            <div data-thq="thq-burger-menu" className="home-burger-menu">
              <div className="home-hamburger">
                <img alt="image" src="/hamburger.svg" className="home-icon10" />
              </div>
            </div>
            <div data-thq="thq-mobile-menu" className="home-mobile-menu">
              <div
                data-thq="thq-mobile-menu-nav"
                data-role="Nav"
                className="home-nav1"
              >
                <div className="home-container11">
                  <img
                    alt="image"
                    src="/officex%20(4)-1500h.png"
                    className="home-image10"
                  />
                  <div data-thq="thq-close-menu" className="home-menu-close">
                    <svg viewBox="0 0 1024 1024" className="home-icon11">
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav
                  data-thq="thq-mobile-menu-nav-links"
                  data-role="Nav"
                  className="home-nav2"
                >
                  <Link to="/preseed" className="home-navlink">
                    Buy $OFX
                  </Link>
                  <a href="#product" className="home-link11">
                    Product
                  </a>
                  <a href="#pricing" className="home-link12">
                    Pricing
                  </a>
                  <a
                    href="https://linktr.ee/officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link13"
                  >
                    Socials
                  </a>
                  <a href="#faq" className="home-link14">
                    FAQ
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link15"
                  >
                    Whitepaper
                  </a>
                </nav>
                <div className="home-container12">
                  <a
                    href="https://bit.ly/4fGMVqv"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-register button"
                  >
                    Launch Free App
                  </a>
                </div>
              </div>
              <div className="home-icon-group">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon13"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon15"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon17"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          </header>
        </header>
        <video
          src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2F20225589-hd_1080_1920_24fps%20(1).mp4?alt=media&amp;token=2fd9ace8-8e3a-434b-a5f0-6b4db8dd9dc9"
          loop
          muted
          poster="/back-1500w.png"
          preload="auto"
          autoPlay
          playsInline
          className="home-video1"
        ></video>
        <div id="hero" className="home-hero-content">
          <div className="home-header-container1">
            <div className="home-header11">
              <img
                alt="image"
                src="/group%20207%20(2)-1500h.png"
                className="home-image11"
              />
              <h1 className="home-heading10">
                <span>OfficeX</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption10">Where Freedom Works</p>
          </div>
          <a
            href="https://drive.officex.app?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=homepage&amp;utm_term=launch_app&amp;utm_content=hero_where_freedom_works"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link16 button"
          >
            <span className="home-text103">
              <span>Launch App</span>
              <br></br>
            </span>
          </a>
          <span className="home-text106">
            Instant Access | No Signup Required
          </span>
        </div>
      </section>
      <section id="product" className="home-get-started">
        <div className="home-header-container2">
          <div className="home-header12">
            <h2 className="home-heading11">Superior to Google Docs</h2>
            <p className="home-caption11">
              OfficeX has everything your organization needs for work. A neutral
              replacement for Google &amp; Microsoft.
            </p>
          </div>
          <div className="home-button1">
            <div className="home-container13">
              <a
                href="https://drive.officex.app?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=homepage&amp;utm_term=launch_app&amp;utm_content=below_folder_superior_gdocs"
                target="_blank"
                rel="noreferrer noopener"
                className="home-button2 button"
              >
                <span className="home-text107">Launch Free App</span>
              </a>
              <a
                href="https://officex.app/buy"
                target="_blank"
                rel="noreferrer noopener"
                className="home-button3 button"
              >
                <span>Buy $OFFICEX</span>
              </a>
            </div>
            <span className="home-text109">
              Instant Access | No Signup Required
            </span>
          </div>
          <img
            alt="image"
            src="/group%20221-200h.png"
            className="home-image12"
          />
        </div>
      </section>
      <section className="home-slides">
        <div className="home-slide-titles">
          <div className="home-slide-title1 slide-title">
            <span className="home-text110">Storage</span>
          </div>
          <div className="home-slide-title2 slide-title slide-title-active">
            <span className="home-text111">Documents</span>
          </div>
          <div className="home-slide-title3 slide-title">
            <span className="home-text112">Spreadsheets</span>
          </div>
          <div className="home-slide-title4 slide-title">
            <span className="home-text113">Presentations</span>
          </div>
        </div>
        <div className="home-slider1 slider blaze-slider">
          <div className="home-slider-container blaze-container">
            <div className="home-slider-track-container blaze-track-container">
              <div className="home-slider-track blaze-track">
                <div data-role="current-banner" className="home-slide1 banner">
                  <img
                    alt="image"
                    src="/ef4aba-min-400h.png"
                    className="home-image13"
                  />
                  <div className="home-content10">
                    <img
                      alt="image"
                      src="/group%20203-400h.png"
                      className="home-image14"
                    />
                    <div className="home-header13">
                      <h3 className="home-heading12">Storage</h3>
                      <p className="home-caption12">
                        Keep your files safe &amp; organized on your computer,
                        or shared on a secure decentralized cloud. Private file
                        storage.
                      </p>
                    </div>
                    <a
                      href="https://drive.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link17"
                    >
                      <div className="home-more1">
                        <span className="home-caption13">
                          <span className="home-text114">Launch App</span>
                          <br></br>
                        </span>
                        <svg viewBox="0 0 1024 1024" className="home-icon19">
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div data-role="current-banner" className="home-slide2 banner">
                  <img
                    alt="image"
                    src="/9fa5-min-400h.png"
                    className="home-image15"
                  />
                  <div className="home-content11">
                    <img
                      alt="image"
                      src="/group%20204-400h.png"
                      className="home-image16"
                    />
                    <div className="home-header14">
                      <h3 className="home-heading13">Documents</h3>
                      <p className="home-caption14">
                        Write and edit documents with ease. No signup required,
                        realtime collaboration &amp; a professional author
                        experience.
                      </p>
                    </div>
                    <a
                      href="https://docs.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link18"
                    >
                      <div className="home-more2">
                        <span className="home-caption15">Launch App</span>
                        <svg viewBox="0 0 1024 1024" className="home-icon21">
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div data-role="current-banner" className="home-slide3 banner">
                  <img
                    alt="image"
                    src="/ded-21b8daac25e8-min-400h.png"
                    className="home-image17"
                  />
                  <div className="home-content12">
                    <img
                      alt="image"
                      src="/group%20205-400h.png"
                      className="home-image18"
                    />
                    <div className="home-header15">
                      <h3 className="home-heading14">Spreadsheets</h3>
                      <p className="home-caption16">
                        Calculate powerful models &amp; rich volume of data, on
                        a smooth infinite spreadsheet. Simple and expressive.
                      </p>
                    </div>
                    <a
                      href="https://sheets.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link19"
                    >
                      <div className="home-more3">
                        <span className="home-caption17">Launch App</span>
                        <svg viewBox="0 0 1024 1024" className="home-icon23">
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div data-role="current-banner" className="home-slide4 banner">
                  <img
                    alt="image"
                    src="/f431251-min-400h.png"
                    className="home-image19"
                  />
                  <div className="home-content13">
                    <img
                      alt="image"
                      src="/group%20206-400h.png"
                      className="home-image20"
                    />
                    <div className="home-header16">
                      <h3 className="home-heading15">Presentations</h3>
                      <p className="home-caption18">
                        Deliver rich presentations with confidence. Easy to
                        design, or clone from pre-built templates.
                      </p>
                    </div>
                    <a
                      href="https://decks.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link20"
                    >
                      <div className="home-more4">
                        <span className="home-caption19">Launch App</span>
                        <svg viewBox="0 0 1024 1024" className="home-icon25">
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-controls1">
              <button
                data-role="previous-banner-button"
                className="home-previous1 blaze-prev button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon27">
                  <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <button
                data-role="next-banner-button"
                className="home-next1 blaze-next button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon29">
                  <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="home-container15">
            <Script
              html={`<script defer>
const el = document.querySelector('.blaze-slider')

new BlazeSlider(el, {
  all: {
    slidesToShow: 3,
    slideGap: '80px',
    loop: true,
    enableAutoplay: true,
  },
})
</script>`}
            ></Script>
          </div>
        </div>
      </section>
      <section className="home-slider2">
        <div className="home-header17">
          <h2 className="home-heading16">
            <span>Manifesto </span>
            <span className="home-text117">Freedom</span>
          </h2>
        </div>
        <div className="home-selector">
          <p className="home-caption20">
            <span className="home-text118">
              OfficeX is an entrepreneurial status symbol.
            </span>
            <br className="home-text119"></br>
            <br className="home-text120"></br>
            <span className="home-text121">Superior quality</span>
            <span className="home-text122">
              {" "}
              at an unbeatable price - free unlimited forever. It&apos;s
              immortal software on the blockchain.
            </span>
            <br className="home-text123"></br>
            <br className="home-text124"></br>
            <span className="home-text125">
              2 billion people pay $65B per year to Google &amp; Microsoft for
              workspace software but don&apos;t own anything. They can 
            </span>
            <span className="home-text126">freeze your life</span>
            <span className="home-text127">.</span>
            <br className="home-text128"></br>
            <br className="home-text129"></br>
            <span className="home-text130">OfficeX is sovereign</span>
            <span className="home-text131">
              {" "}
              because no one controls your digital life except you (guaranteed
              by cryptography). 
            </span>
            <span className="home-text132">
              Every file shared is a fashion statement for freedom.
            </span>
            <br className="home-text133"></br>
            <br className="home-text134"></br>
            <span className="home-text135">
              Join people who
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text136">forge their own path</span>
            <span className="home-text137"> - </span>
            <span className="home-text138">OfficeX was made for you.</span>
            <br className="home-text139"></br>
            <br className="home-text140"></br>
            <span className="home-text141">Be</span>
            <span className="home-text142"> Untouchable.</span>
            <br className="home-text143"></br>
            <br className="home-text144"></br>
            <span className="home-text145">Terran</span>
            <br className="home-text146"></br>
            <span className="home-text147">Founder OfficeX</span>
            <br></br>
          </p>
        </div>
        <a
          href="https://bit.ly/officex-whitepaper"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link21"
        >
          <div className="home-more5">
            <span className="home-caption21">Read Whitepaper</span>
            <svg viewBox="0 0 1024 1024" className="home-icon31">
              <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
        </a>
      </section>
      <section className="home-statistics">
        <div className="home-content14">
          <div className="home-stat1">
            <h3 className="home-header18">$1.69M</h3>
            <span className="home-caption22">
              Consectetur adipiscing
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header19">Multiverse</h3>
            <span className="home-caption23">Unde omnis iste</span>
          </div>
          <div className="home-stat3">
            <h3 className="home-header20">500K</h3>
            <span className="home-caption24">Accusantium doloremque</span>
          </div>
        </div>
      </section>
      <section className="home-objectives">
        <div className="home-content15">
          <span className="home-text149">PRINCIPLES</span>
          <div className="home-objectives-list">
            <div className="objective">
              <h3 className="home-text150">Superior Quality</h3>
              <p className="home-text151">
                Works offline, no download &amp; fully customizeable. Smoother,
                faster, easier than traditional options.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text152">Free Unlimited Enterprise</h3>
              <p className="home-text153">
                All features available with no restrictions, unlimited accounts
                &amp; instant setup. Free Forever.
              </p>
            </div>
            <div className="objective home-objective3">
              <h3 className="home-text154">100% Sovereign</h3>
              <p className="home-text155">
                Neutral private software owned by you, immune to censorship
                &amp; bans. Available to anyone anywhere.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home-customer">
        <div className="home-header21">
          <h2 className="home-heading17">
            <span>Sovereign is </span>
            <span className="home-text157">Sexy</span>
            <br></br>
          </h2>
          <span className="home-text159">
            OfficeX is loved by Untouchable Entrepreneurs
          </span>
          <div className="home-container16">
            <div className="home-container17">
              <div className="home-quote1">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_29263C8D-B932-4BF4-BC89-4F865D983867%20(2).mp4?alt=media&amp;token=6e657465-77d6-4057-bdeb-6359ee25a736"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FScreenshot%202024-08-13%20at%201.23.14%E2%80%AFAM-min.png?alt=media&amp;token=aaa5aaf4-1e4d-49b5-9210-5693118461eb"
                  preload="auto"
                  autoPlay="true"
                  playsInline="true"
                  className="home-video2"
                ></video>
                <div className="home-author1">
                  <span className="home-name1">@abundancestation</span>
                  <span className="home-location1">322k Instagram</span>
                </div>
              </div>
            </div>
            <div className="home-container18">
              <div className="home-quote2">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_46BCCB11-2BEF-4920-B20D-60EC04ECD714.MOV?alt=media&amp;token=736c2de2-368a-439e-996f-094559d05f4f"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8551%20(1)-min.jpg?alt=media&amp;token=64a40dff-12e4-4019-af82-b31f81def319"
                  preload="auto"
                  className="home-video3"
                ></video>
                <div className="home-author2">
                  <span className="home-name2">@overwealthacademy</span>
                  <span className="home-location2">68k Instagram</span>
                </div>
              </div>
            </div>
            <div className="home-container19">
              <div className="home-quote3">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2FIMG_8469.mp4?alt=media&amp;token=a7c439cc-6608-4734-b8b3-26b4ee2abda5"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FScreenshot%202024-08-13%20at%203.01.45%E2%80%AFAM-min.png?alt=media&amp;token=f3bd3bd4-02d3-4c1d-b497-6ecb6fa3d3ca"
                  className="home-video4"
                ></video>
                <div className="home-author3">
                  <span className="home-name3">@valuance</span>
                  <span className="home-location3">184k Instagram</span>
                </div>
              </div>
            </div>
            <div className="home-container20">
              <div className="home-quote4">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_7E4CDCED-5FC0-4F1A-B3ED-A9FDAAAB8A68%202.mp4?alt=media&amp;token=9b2a1d19-1925-43d1-b939-96594e7cc957"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8554%20(1)-min.jpg?alt=media&amp;token=c5d93cee-b79a-4666-9d3a-5542c833e564"
                  className="home-video5"
                ></video>
                <div className="home-author4">
                  <span className="home-name4">@motigentt</span>
                  <span className="home-location4">76k Instagram</span>
                </div>
              </div>
            </div>
            <div className="home-container21">
              <div className="home-quote5">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2F-9037149440928443275.MP4?alt=media&amp;token=452e6182-716f-4a5a-8d9e-725bfe6b1027"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8550%20(1)-min.jpg?alt=media&amp;token=e83ee981-e85d-476a-a026-48efffb1b930"
                  className="home-video6"
                ></video>
                <div className="home-author5">
                  <span className="home-name5">@aetherium10</span>
                  <span className="home-location5">47k Instagram</span>
                </div>
              </div>
            </div>
            <div className="home-container22">
              <div className="home-quote6">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_B3B8CA23-E0D0-4022-BBD7-1E486730F059%20(1).MOV?alt=media&amp;token=1cae5b05-e689-439d-b883-1bf58953aaeb"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8555%20(1)-min.jpg?alt=media&amp;token=32bea1ca-3098-40f0-914c-d2d9f80acebf"
                  className="home-video7"
                ></video>
                <div className="home-author6">
                  <span className="home-name6">@wifiequalsmoney</span>
                  <span className="home-location6">45k Instagram</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-controls2">
          <button className="home-previous2 button">
            <svg viewBox="0 0 1024 1024" className="home-icon33">
              <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
          </button>
          <button className="home-next2 button">
            <svg viewBox="0 0 1024 1024" className="home-icon35">
              <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </button>
          <button className="home-button4 button">
            <span>
              <span>View all cases</span>
              <br></br>
            </span>
          </button>
        </div>
      </section>
      <section className="home-growth">
        <div className="home-content16">
          <div className="home-header22">
            <div className="home-header23">
              <h2 className="home-heading18">Full Features</h2>
            </div>
            <p className="home-caption25">
              All features with unlimited use, free forever
            </p>
            <div className="home-checkmarks1">
              <div className="home-check10">
                <div className="home-mark10">
                  <svg viewBox="0 0 1024 1024" className="home-icon37">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text163">All Features Available</span>
              </div>
              <div className="home-check11">
                <div className="home-mark11">
                  <svg viewBox="0 0 1024 1024" className="home-icon39">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text164">Private &amp; Secure</span>
              </div>
              <div className="home-check12">
                <div className="home-mark12">
                  <svg viewBox="0 0 1024 1024" className="home-icon41">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text165">100% Free Forever</span>
              </div>
              <div className="home-check13">
                <div className="home-mark13">
                  <svg viewBox="0 0 1024 1024" className="home-icon43">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text166">
                  Cloud starting at $0.01 / month
                </span>
              </div>
              <div className="home-check14">
                <div className="home-mark14">
                  <svg viewBox="0 0 1024 1024" className="home-icon45">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text167">
                  Still works during Apocalypse
                </span>
              </div>
            </div>
          </div>
          <div className="home-testimonial1">
            <div className="home-content17">
              <span className="home-text168">MISSION</span>
              <p className="home-text169">
                <span>“E</span>
                <span>
                  ssential digital tools accessible to all, helping solve global
                  challenges and sustainable development.
                </span>
                <span>”</span>
              </p>
            </div>
            <div className="home-information1">
              <div className="home-author7">
                <img
                  alt="image"
                  src="/un-logo-200h.png"
                  className="home-icon47"
                />
                <span className="home-name7">
                  <span>United Nations </span>
                  <br></br>
                  <span>Digital Public Goods Alliance</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-images1">
          <div className="home-square1"></div>
          <img
            alt="image"
            src="/toyota-zpu-1400w.jpg"
            className="home-image21"
          />
        </div>
      </section>
      <section className="home-experience">
        <div className="home-images2">
          <div className="home-square2"></div>
          <img
            alt="image"
            src="/image%20112%20(1)-min-1400w.png"
            className="home-image22"
          />
        </div>
        <div className="home-content18">
          <div className="home-header24">
            <div className="home-header-container3">
              <div className="home-header25">
                <h2 className="home-heading19">
                  Unlimited Enterprise Accounts
                </h2>
              </div>
              <p className="home-caption26">
                Scaleable to billions of people, instant setup
              </p>
            </div>
            <div className="home-checkmarks2">
              <div className="home-check15">
                <div className="home-mark15">
                  <svg viewBox="0 0 1024 1024" className="home-icon48">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text176">Free Unlimited Accounts</span>
              </div>
              <div className="home-check16">
                <div className="home-mark16">
                  <svg viewBox="0 0 1024 1024" className="home-icon50">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text177">
                  Comprehensive Admin Controls
                </span>
              </div>
              <div className="home-check17">
                <div className="home-mark17">
                  <svg viewBox="0 0 1024 1024" className="home-icon52">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text178">
                  Zero-Config Trustless Cloud
                </span>
              </div>
              <div className="home-check18">
                <div className="home-mark18">
                  <svg viewBox="0 0 1024 1024" className="home-icon54">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text179">End to End Encryption</span>
              </div>
              <div className="home-check19">
                <div className="home-mark19">
                  <svg viewBox="0 0 1024 1024" className="home-icon56">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text180">Open Source Whitelabel</span>
              </div>
            </div>
          </div>
          <div className="home-testimonial2">
            <div className="home-content19">
              <span className="home-text181">MISSION</span>
              <p className="home-text182">
                “Digital sovereignty means independence from foreign tech
                companies, starting with tools for collaboration and
                productivity.”
              </p>
            </div>
            <div className="home-information2">
              <div className="home-author8">
                <img
                  alt="image"
                  src="/mask%20group%20(14)-200h.png"
                  className="home-icon58"
                />
                <span className="home-name8">
                  <span>
                    European Union
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <br></br>
                  <span>Sovereign Workspace Initiative</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-create1">
        <div className="home-content20">
          <div className="home-header26">
            <h2 className="home-heading20">
              <span>Join OfficeX</span>
              <br></br>
            </h2>
          </div>
          <span className="home-text188">Where Freedom Works</span>
          <div className="home-container23">
            <a
              href="https://drive.officex.app?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=homepage&amp;utm_term=launch_app&amp;utm_content=middle_cta_join_officex"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link22 button"
            >
              <span className="home-text189">
                <span>Launch Free App</span>
                <br></br>
              </span>
            </a>
            <a
              href="https://officex.app/buy"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link23 button"
            >
              <span className="home-text192">
                <span>Buy $OFFICEX</span>
                <br></br>
              </span>
            </a>
          </div>
          <span className="home-text195">
            Instant Access | No Signup Required
          </span>
        </div>
      </section>
      <section id="pricing" className="home-comparision">
        <div className="home-header-container4">
          <div className="home-header27">
            <h2 className="home-heading21">
              <span>OfficeX Compared</span>
              <br></br>
            </h2>
          </div>
          <p className="home-caption27">Humanity Friendly Pricing</p>
        </div>
        <div className="home-table">
          <div className="home-row10">
            <div className="home-headers10">
              <span className="home-text198">Placeholder</span>
            </div>
            <div className="home-plans10">
              <div className="home-row11">
                <span className="home-text199">OFFICEX</span>
                <span className="home-text200">GOOGLE WORKSPACE</span>
                <span className="home-text201">MICROSOFT OFFICE</span>
              </div>
            </div>
          </div>
          <div className="home-row12">
            <div className="home-headers11">
              <span className="home-text202">
                Documents, Spreadsheets &amp; Presentations
              </span>
            </div>
            <div className="home-plans11">
              <div className="home-row13">
                <div className="value">
                  <Check rootClassName="checkroot-class-name5"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name6"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name9"></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row14">
            <div className="home-headers12">
              <span className="home-text203">Cloud Storage &amp; Sharing</span>
            </div>
            <div className="home-plans12">
              <div className="home-row15">
                <div className="value">
                  <Check rootClassName="checkroot-class-name13"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name14"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name15"></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row16">
            <div className="home-headers13">
              <span className="home-text204">Free Accounts</span>
            </div>
            <div className="home-plans13">
              <div className="home-row17">
                <div className="value">
                  <span className="home-text205">Unlimited</span>
                </div>
                <div className="value">
                  <span className="home-text206">Limit 3</span>
                </div>
                <div className="value">
                  <span className="home-text207">Limit 3</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row18">
            <div className="home-headers14">
              <span className="home-text208">
                <span>
                  x1 Premium Account / Students
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text210">Save 99%</span>
              </span>
            </div>
            <div className="home-plans14">
              <div className="home-row19">
                <div className="value">
                  <span className="home-text211">~$0.01 usd gas</span>
                </div>
                <div className="value">
                  <span className="home-text212">$144 / year</span>
                </div>
                <div className="value">
                  <span className="home-text213">$72 / year</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row20">
            <div className="home-headers15">
              <span className="home-text214">
                <span>
                  x10 Accounts / Small Business
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text216">Save 99%</span>
              </span>
            </div>
            <div className="home-plans15">
              <div className="home-row21">
                <div className="value">
                  <span className="home-text217">~$10 gas/year</span>
                </div>
                <div className="value">
                  <span className="home-text218">$1440 / year</span>
                </div>
                <div className="value">
                  <span className="home-text219">$720 / year</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row22">
            <div className="home-headers16">
              <span className="home-text220">
                <span>
                  x100 Accounts / Agencies
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text222">Save 99%</span>
              </span>
            </div>
            <div className="home-plans16">
              <div className="home-row23">
                <div className="value">
                  <span className="home-text223">~$100 gas/year</span>
                </div>
                <div className="value">
                  <span className="home-text224">$14,440 / year</span>
                </div>
                <div className="value">
                  <span className="home-text225">$7200 / year</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row24">
            <div className="home-headers17">
              <span className="home-text226">
                Enterprise &amp; Government Integration
              </span>
            </div>
            <div className="home-plans17">
              <div className="home-row25">
                <div className="value">
                  <span className="home-text227">Free &amp; Instant</span>
                </div>
                <div className="value">
                  <span className="home-text228">Starts at $500k</span>
                </div>
                <div className="value">
                  <span className="home-text229">Starts at $500k</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row26">
            <div className="home-headers18">
              <span className="home-text230">Developer Platform</span>
            </div>
            <div className="home-plans18">
              <div className="home-row27">
                <div className="value">
                  <Check rootClassName="checkroot-class-name16"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name17"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name18"></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row28">
            <div className="home-headers19">
              <span className="home-text231">Works Offline (no internet)</span>
            </div>
            <div className="home-plans19">
              <div className="home-row29">
                <div className="value">
                  <Check rootClassName="checkroot-class-name10"></Check>
                </div>
                <div className="value">
                  <span className="home-text232">Limited Functionality</span>
                </div>
                <div className="value">
                  <span className="home-text233">Download Required</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row30">
            <div className="home-headers20">
              <span className="home-text234">
                Sovereign Rights / Own Your Data
              </span>
            </div>
            <div className="home-plans20">
              <div className="home-row31">
                <div className="value">
                  <Check rootClassName="checkroot-class-name7"></Check>
                </div>
                <div className="value">
                  <span className="home-text235">Foreign Owned</span>
                </div>
                <div className="value">
                  <span className="home-text236">Foreign Owned</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row32">
            <div className="home-headers21">
              <span className="home-text237">
                Total Privacy / E2E Encryption
              </span>
            </div>
            <div className="home-plans21">
              <div className="home-row33">
                <div className="value">
                  <Check rootClassName="checkroot-class-name11"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name3"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name10"></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row34">
            <div className="home-headers22">
              <span className="home-text238">
                Free Speech / Censorship-Resistent
              </span>
            </div>
            <div className="home-plans22">
              <div className="home-row35">
                <div className="value">
                  <Check rootClassName="checkroot-class-name4"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name11"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name5"></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row36">
            <div className="home-headers23">
              <span className="home-text239">Open Source / Whitelabel</span>
            </div>
            <div className="home-plans23">
              <div className="home-row37">
                <div className="value">
                  <Check rootClassName="checkroot-class-name12"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name14"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name15"></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row38">
            <div className="home-headers24">
              <span className="home-text240">Sexy / Software</span>
            </div>
            <div className="home-plans24">
              <div className="home-row39">
                <div className="value">
                  <Check rootClassName="checkroot-class-name19"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name12"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name13"></Line>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-data">
        <div className="home-header-container5">
          <div className="home-header28">
            <div className="home-row40">
              <h2 className="home-heading22">
                <span>What makes OfficeX Invincible?</span>
                <br></br>
              </h2>
            </div>
          </div>
          <p className="home-caption28">
            <span>Autonomous software running on the World Computer. </span>
            <span>Click below to learn more</span>
            <br></br>
          </p>
        </div>
        <div className="home-content21">
          <div className="home-image23">
            <img
              alt="image"
              src="/hd-wallpaper-satellite-eartth-space-1400w.jpg"
              className="home-image24"
            />
          </div>
          <div className="home-accordion">
            <div
              data-role="accordion-container"
              className="home-element10 accordion accordion-active"
            >
              <div>
                <h3 className="home-header29">100% Ownership</h3>
                <p
                  data-role="accordion-content"
                  className="home-content23 accordion-active accordion-content"
                >
                  You own everything you create. No one has access to your files
                  or controls what you do with them. Modify the software however
                  you want. No paid subscriptions - Free Forever.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name2"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header30">Trustless Blockchain</h3>
                <p
                  data-role="accordion-content"
                  className="home-content25 accordion-active accordion-content"
                >
                  Upload &amp; Forget to a truly neutral cloud. Where autonomous
                  software runs at infinite scale with global availability &amp;
                  gas metered pricing. Zero developer trust or maintenance
                  required. Forever available to humanity.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name3"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header31">Censorship Resistent</h3>
                <p
                  data-role="accordion-content"
                  className="home-content27 accordion-active accordion-content"
                >
                  Free speech guaranteed by design. OfficeX survives internet
                  shutdowns using offline peer-to-peer collaboration. Censorship
                  resistant thanks to a permissionless blockchain where endless
                  private copies can be made. Enshrining our human rights of
                  collaboration.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header32">Privacy Guaranteed</h3>
                <p
                  data-role="accordion-content"
                  className="home-content29 accordion-active accordion-content"
                >
                  End-to-End Encryption offers maximum privacy. Only you can
                  decide who has access. Not even the government or OfficeX
                  developers can break in. Cryptographically guaranteed by magic
                  math.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name1"></IconContainer>
            </div>
            <div>
              <div className="home-container25">
                <Script
                  html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
                ></Script>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className="home-faq">
        <div className="home-header33">
          <h2 className="home-heading23">
            <span>Frequently asked </span>
            <span className="home-text247">questions</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </h2>
        </div>
        <div className="home-content30">
          <div className="home-column1">
            <div className="home-element14">
              <h3 className="home-header34">How can it be free forever?</h3>
              <p className="home-content31">
                OfficeX was designed to run as a standalone webapp without
                internet connection. Since we have zero server costs, the app
                can be 100% free forever. However, storing files on the cloud
                for sharing will cost ~$0.01 in gas fees (see gas faq below)
              </p>
            </div>
            <div className="home-element15">
              <h3 className="home-header35">
                How does Cloud Pricing work? What is Gas?
              </h3>
              <p className="home-content32">
                Gas is the $0.01 USD you pay for connecting to the blockchain
                internet. With gas, you only pay for your tiny share of cloud
                consumption - aka usage based pricing. Gas is 100x cheaper than
                paying monthly software subscriptions.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="home-element16">
              <h3 className="home-header36">
                How is OfficeX &quot;Apocalypse-Proof&quot;?
              </h3>
              <p className="home-content33">
                OfficeX is &quot;apocalypse-proof&quot; because you can still
                use it if civilization collapsed (no internet, no google, no
                microsoft). Available Offline, No Signup Required.
              </p>
            </div>
          </div>
          <div className="home-column2">
            <div className="home-element17">
              <h3 className="home-header37">
                Why would rebels &amp; governments use OfficeX?
              </h3>
              <p className="home-content34">
                Rebels &amp; Governments both need office software with serious
                sovereign protections. They often have budget constraints and
                fear leaking sensitive documents to foreign tech companies.
                OfficeX is a neutral alternative, running on a secure neutral
                cloud, with zero setup costs. It is extremely convenient and
                scales with the mission. 
              </p>
            </div>
            <div className="home-element18">
              <h3 className="home-header38">Why does it need Blockchain?</h3>
              <p className="home-content35">
                OfficeX uses blockchain technology because it simplifies
                document sharing, security &amp; servers. Blockchain also makes
                payments easier by accepting crypto.
              </p>
            </div>
            <div className="home-element19">
              <h3 className="home-header39">Is there a token?</h3>
              <p className="home-content36">
                Yes, OfficeX offers $OFX as its utility &amp; governance token.
                See the Whitepaper for tokenomics &amp; roadmap. Not financial
                advice. Airdrops scheduled.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home-create2">
        <div className="home-content37">
          <div className="home-header40">
            <h2 className="home-heading24">
              <span>Join OfficeX</span>
              <br></br>
            </h2>
          </div>
          <span className="home-text251">Where Freedom Works</span>
          <div className="home-container26">
            <a
              href="https://drive.officex.app?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=homepage&amp;utm_term=launch_app&amp;utm_content=end_cta_join_officex"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link24 button"
            >
              <span className="home-text252">
                <span>Launch Free App</span>
                <br></br>
              </span>
            </a>
            <a
              href="https://officex.app/buy"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link25 button"
            >
              <span className="home-text255">
                <span>Buy $OFFICEX</span>
                <br></br>
              </span>
            </a>
          </div>
          <span className="home-text258">
            Instant Access | No Signup Required
          </span>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content38">
          <div className="home-main">
            <div className="home-branding2">
              <img
                alt="image"
                src="/officex%20(3)-200h.png"
                className="home-image25"
              />
              <span className="home-text259">
                <span>Free Unlimited Workspace.</span>
                <br></br>
                <span>
                  Superior Web3 Google Docs.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br></br>
                <span>Where Freedom Works.</span>
                <br></br>
                <br></br>
                <span>admin@officex.app</span>
                <br></br>
              </span>
            </div>
            <div className="home-links">
              <div className="home-column3">
                <span className="home-header41">Company</span>
                <div className="home-list1">
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link26"
                  >
                    About
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link27"
                  >
                    Whitepaper
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link28"
                  >
                    Tokenomics
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link29"
                  >
                    <span>Community</span>
                    <br></br>
                  </a>
                  <a
                    href="https://github.com/officex"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link30"
                  >
                    <span>Github</span>
                    <br></br>
                  </a>
                  <a
                    href="https://bit.ly/officex-tos"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link31"
                  >
                    Terms
                  </a>
                </div>
              </div>
              <div className="home-column4">
                <span className="home-header42">Social</span>
                <div className="home-list2">
                  <a
                    href="https://x.com/officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link32"
                  >
                    Twitter X
                  </a>
                  <a
                    href="https://www.tiktok.com/@officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link33"
                  >
                    TikTok
                  </a>
                  <a
                    href="https://www.instagram.com/officexapp/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link34"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.youtube.com/@OfficeXApp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link35"
                  >
                    YouTube
                  </a>
                  <a
                    href="https://www.linkedin.com/in/terran-principal-a94163323/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link36"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="https://medium.com/@officex"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link37"
                  >
                    Medium
                  </a>
                  <a
                    href="https://t.me/officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link38"
                  >
                    Telegram
                  </a>
                  <a
                    href="https://bit.ly/officex-discord"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link39"
                  >
                    Discord
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text274">
              © 2024 OfficeX - All rights reserved
            </span>
            <button data-role="scroll-top" className="home-button5 button">
              <img alt="image" src="/arrow.svg" className="home-image26" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="home-container28">
          <Script
            html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  );
};

export default Home;
