import React from "react";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Check from "../components/check";
import Line from "../components/line";
import IconContainer from "../components/icon-container";
import "./ofx-pre-seed-sale.css";

const OFXPreSeedSale = (props) => {
  return (
    <div className="ofx-pre-seed-sale-container10">
      <Helmet>
        <title>OFX-Pre-Seed-Sale - OfficeX App</title>
        <meta
          name="description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. For Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OFX-Pre-Seed-Sale - OfficeX App" />
        <meta
          property="og:description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. Made for Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section id="ico-hero" className="ofx-pre-seed-sale-hero">
        <header className="ofx-pre-seed-sale-header10">
          <header data-thq="thq-navbar" className="ofx-pre-seed-sale-navbar">
            <a
              href="https://officex.app"
              target="_blank"
              rel="noreferrer noopener"
              className="ofx-pre-seed-sale-link10"
            >
              <img
                alt="image"
                src="/officex%20(4)-1500h.png"
                className="ofx-pre-seed-sale-branding1"
              />
            </a>
            <div
              data-thq="thq-burger-menu"
              className="ofx-pre-seed-sale-burger-menu"
            >
              <div className="ofx-pre-seed-sale-hamburger">
                <img
                  alt="image"
                  src="/hamburger.svg"
                  className="ofx-pre-seed-sale-icon10"
                />
              </div>
            </div>
            <div
              data-thq="thq-mobile-menu"
              className="ofx-pre-seed-sale-mobile-menu"
            >
              <div
                data-thq="thq-mobile-menu-nav"
                data-role="Nav"
                className="ofx-pre-seed-sale-nav1"
              >
                <div className="ofx-pre-seed-sale-container11">
                  <img
                    alt="image"
                    src="/officex%20(4)-1500h.png"
                    className="ofx-pre-seed-sale-image10"
                  />
                  <div
                    data-thq="thq-close-menu"
                    className="ofx-pre-seed-sale-menu-close"
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="ofx-pre-seed-sale-icon11"
                    >
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav
                  data-thq="thq-mobile-menu-nav-links"
                  data-role="Nav"
                  className="ofx-pre-seed-sale-nav2"
                >
                  <a href="#product" className="ofx-pre-seed-sale-link11">
                    Product
                  </a>
                  <a href="#pricing" className="ofx-pre-seed-sale-link12">
                    Pricing
                  </a>
                  <a
                    href="https://linktr.ee/officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link13"
                  >
                    Socials
                  </a>
                  <a href="#faq" className="ofx-pre-seed-sale-link14">
                    FAQ
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link15"
                  >
                    Whitepaper
                  </a>
                </nav>
                <div className="ofx-pre-seed-sale-container12">
                  <a
                    href="https://bit.ly/4fGMVqv"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-register button"
                  >
                    Launch Free App
                  </a>
                </div>
              </div>
              <div className="ofx-pre-seed-sale-icon-group">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="ofx-pre-seed-sale-icon13"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="ofx-pre-seed-sale-icon15"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="ofx-pre-seed-sale-icon17"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          </header>
        </header>
        <video
          src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2F20225589-hd_1080_1920_24fps%20(1).mp4?alt=media&amp;token=2fd9ace8-8e3a-434b-a5f0-6b4db8dd9dc9"
          loop
          muted
          poster="/back-1500w.png"
          preload="auto"
          autoPlay
          playsInline
          className="ofx-pre-seed-sale-video10"
        ></video>
        <div className="ofx-pre-seed-sale-hero-content">
          <div className="ofx-pre-seed-sale-header-container1">
            <div className="ofx-pre-seed-sale-header11">
              <img
                alt="image"
                src="/group%20207%20(2)-200h.png"
                className="ofx-pre-seed-sale-image11"
              />
              <h1 className="ofx-pre-seed-sale-heading10">
                <span>Buy $OFX</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br></br>
              </h1>
            </div>
            <p className="ofx-pre-seed-sale-caption10">
              <span className="ofx-pre-seed-sale-text103">
                OfficeX Token Pre-Seed
              </span>
              <br></br>
              <span>Buy floor price $0.01 usd</span>
              <br></br>
            </p>
          </div>
          <div className="ofx-pre-seed-sale-container13">
            <div className="ofx-pre-seed-sale-container14">
              <span className="ofx-pre-seed-sale-text107">
                Send USDT on Ethereum to:
              </span>
              <input
                type="text"
                value="0xed75933Fc4Ebb1284833549443BF184531C0A6ac"
                placeholder="Ethereum Contract"
                className="ofx-pre-seed-sale-textinput1 input"
              />
            </div>
            <div className="ofx-pre-seed-sale-container15">
              <span className="ofx-pre-seed-sale-text108">
                You will receive $OFX on Ethereum
              </span>
              <input
                type="text"
                value="Airdrop from on-chain history - Sept 30th 2024"
                placeholder="Ethereum Contract"
                className="ofx-pre-seed-sale-textinput2 input"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-customer1">
        <div className="ofx-pre-seed-sale-header12">
          <h2 className="ofx-pre-seed-sale-heading11">
            <span>Path to 1 Billion Users</span>
            <br></br>
          </h2>
          <span className="ofx-pre-seed-sale-text111">
            Join the 1000x Journey
          </span>
          <a
            href="https://bit.ly/officex-whitepaper"
            target="_blank"
            rel="noreferrer noopener"
            className="ofx-pre-seed-sale-link16"
          >
            <div className="ofx-pre-seed-sale-more1">
              <span className="ofx-pre-seed-sale-caption11">
                Read Whitepaper
              </span>
              <svg viewBox="0 0 1024 1024" className="ofx-pre-seed-sale-icon19">
                <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </a>
          <div className="ofx-pre-seed-sale-container16">
            <div className="ofx-pre-seed-sale-container17">
              <div className="ofx-pre-seed-sale-quote10">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_29263C8D-B932-4BF4-BC89-4F865D983867%20(2).mp4?alt=media&amp;token=6e657465-77d6-4057-bdeb-6359ee25a736"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FScreenshot%202024-08-13%20at%201.23.14%E2%80%AFAM-min.png?alt=media&amp;token=aaa5aaf4-1e4d-49b5-9210-5693118461eb"
                  preload="auto"
                  autoPlay="true"
                  playsInline="true"
                  className="ofx-pre-seed-sale-video11"
                ></video>
                <h2 className="ofx-pre-seed-sale-text112">$0.01 USDT</h2>
                <div className="ofx-pre-seed-sale-author10">
                  <span className="ofx-pre-seed-sale-name10">
                    Pre-Seed Price
                  </span>
                  <span className="ofx-pre-seed-sale-location10">
                    First 1000 Users
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container18">
              <div className="ofx-pre-seed-sale-quote11">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_46BCCB11-2BEF-4920-B20D-60EC04ECD714.MOV?alt=media&amp;token=736c2de2-368a-439e-996f-094559d05f4f"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8551%20(1)-min.jpg?alt=media&amp;token=64a40dff-12e4-4019-af82-b31f81def319"
                  preload="auto"
                  className="ofx-pre-seed-sale-video12"
                ></video>
                <h2 className="ofx-pre-seed-sale-text113">10k Users</h2>
                <div className="ofx-pre-seed-sale-author11">
                  <span className="ofx-pre-seed-sale-name11">Private Seed</span>
                  <span className="ofx-pre-seed-sale-location11">
                    10x User Growth
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container19">
              <div className="ofx-pre-seed-sale-quote12">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2FIMG_8469.mp4?alt=media&amp;token=a7c439cc-6608-4734-b8b3-26b4ee2abda5"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FScreenshot%202024-08-13%20at%203.01.45%E2%80%AFAM-min.png?alt=media&amp;token=f3bd3bd4-02d3-4c1d-b497-6ecb6fa3d3ca"
                  className="ofx-pre-seed-sale-video13"
                ></video>
                <h2 className="ofx-pre-seed-sale-text114">100k Users</h2>
                <div className="ofx-pre-seed-sale-author12">
                  <span className="ofx-pre-seed-sale-name12">
                    Public Coin Offering
                  </span>
                  <span className="ofx-pre-seed-sale-location12">
                    100x User Growth
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container20">
              <div className="ofx-pre-seed-sale-quote13">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_7E4CDCED-5FC0-4F1A-B3ED-A9FDAAAB8A68%202.mp4?alt=media&amp;token=9b2a1d19-1925-43d1-b939-96594e7cc957"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8554%20(1)-min.jpg?alt=media&amp;token=c5d93cee-b79a-4666-9d3a-5542c833e564"
                  className="ofx-pre-seed-sale-video14"
                ></video>
                <h2 className="ofx-pre-seed-sale-text115">1M Users</h2>
                <div className="ofx-pre-seed-sale-author13">
                  <span className="ofx-pre-seed-sale-name13">
                    Early Adopters
                  </span>
                  <span className="ofx-pre-seed-sale-location13">
                    1000x User Growth
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container21">
              <div className="ofx-pre-seed-sale-quote14">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2F-9037149440928443275.MP4?alt=media&amp;token=452e6182-716f-4a5a-8d9e-725bfe6b1027"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8550%20(1)-min.jpg?alt=media&amp;token=e83ee981-e85d-476a-a026-48efffb1b930"
                  className="ofx-pre-seed-sale-video15"
                ></video>
                <h2 className="ofx-pre-seed-sale-text116">10M Users</h2>
                <div className="ofx-pre-seed-sale-author14">
                  <span className="ofx-pre-seed-sale-name14">
                    Crossing the Chasm
                  </span>
                  <span className="ofx-pre-seed-sale-location14">
                    10,000x User Growth
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container22">
              <div className="ofx-pre-seed-sale-quote15">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_B3B8CA23-E0D0-4022-BBD7-1E486730F059%20(1).MOV?alt=media&amp;token=1cae5b05-e689-439d-b883-1bf58953aaeb"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8555%20(1)-min.jpg?alt=media&amp;token=32bea1ca-3098-40f0-914c-d2d9f80acebf"
                  className="ofx-pre-seed-sale-video16"
                ></video>
                <h2 className="ofx-pre-seed-sale-text117">100M Users</h2>
                <div className="ofx-pre-seed-sale-author15">
                  <span className="ofx-pre-seed-sale-name15">Mainstream</span>
                  <span className="ofx-pre-seed-sale-location15">
                    100,000x User Growth
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ofx-pre-seed-sale-controls1">
          <button className="ofx-pre-seed-sale-previous1 button">
            <svg viewBox="0 0 1024 1024" className="ofx-pre-seed-sale-icon21">
              <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
          </button>
          <button className="ofx-pre-seed-sale-next1 button">
            <svg viewBox="0 0 1024 1024" className="ofx-pre-seed-sale-icon23">
              <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </button>
          <button className="ofx-pre-seed-sale-button1 button">
            <span>
              <span>View all cases</span>
              <br></br>
            </span>
          </button>
        </div>
      </section>
      <section id="faq" className="ofx-pre-seed-sale-faq1">
        <div className="ofx-pre-seed-sale-header13">
          <h2 className="ofx-pre-seed-sale-heading12">
            <span className="ofx-pre-seed-sale-text121">Token Pre-Sale </span>
            <span className="ofx-pre-seed-sale-text122">FAQ</span>
          </h2>
        </div>
        <div className="ofx-pre-seed-sale-content10">
          <div className="ofx-pre-seed-sale-column1">
            <div className="ofx-pre-seed-sale-element10">
              <h3 className="ofx-pre-seed-sale-header14">
                How do I buy $OFX
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h3>
              <p className="ofx-pre-seed-sale-content11">
                <span className="ofx-pre-seed-sale-text123">
                  Send USDT on Ethereum to the wallet address and you will
                  receive $OFX airdrop to the same wallet you send from. Airdrop
                  is tentatively set for Sept 30th 2024 and will be announced on
                  our official X account @officexapp
                </span>
                <br></br>
                <br></br>
                <br></br>
              </p>
              <span className="ofx-pre-seed-sale-text127">
                Send USDT on Ethereum to:
                0xed75933Fc4Ebb1284833549443BF184531C0A6ac
              </span>
              <a
                href="https://x.com/officexapp"
                target="_blank"
                rel="noreferrer noopener"
                className="ofx-pre-seed-sale-link17"
              >
                Announcements on X (Twitter) @officexapp
              </a>
            </div>
            <div className="ofx-pre-seed-sale-element11">
              <h3 className="ofx-pre-seed-sale-header15">
                Explain the Growth Potential
              </h3>
              <p className="ofx-pre-seed-sale-content12">
                2 billion people pay $65B per year for Google Suite &amp;
                Microsoft Office. 300M people use cracked versions due to
                expensive enterprise pricing or government sanctions. This is
                100,000x user growth for a Flagship DApp (currently less than 1k
                users). Timing akin to Bitcoin at $0.60 usd. Preseed round
                closes at $500k raised. Not financial advice.
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element12">
              <h3 className="ofx-pre-seed-sale-header16">
                Is OfficeX Legit? Why Open Source
              </h3>
              <p className="ofx-pre-seed-sale-content13">
                OfficeX is legit because its 100% open source and build in
                public. We ship code daily and lead the charge. Beware of
                imposters - anyone can fork our code but great software is
                a continuous improvement. We lead, they follow.
              </p>
              <a
                href="https://github.com/officexapp"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://github.com/officexapp
              </a>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-column2">
            <div className="ofx-pre-seed-sale-element13">
              <h3 className="ofx-pre-seed-sale-header17">Pre-Seed Perks</h3>
              <p className="ofx-pre-seed-sale-content14">
                Pre-Seed offers $OFX at the best floor price of ~$0.01 USD at a
                $2M FDV. Early holders have most potential for 1000x user
                growth, as well as prestige ownership of a flagship dapp. See
                growth potential explanation.
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element14">
              <h3 className="ofx-pre-seed-sale-header18">
                What are the Tokenomics?
              </h3>
              <p className="ofx-pre-seed-sale-content15">
                $OFX is a fixed supply token representing ownership in OfficeX
                Protocol. It appreciates in value from hardcoded profit margins
                at the smart contract level. When OfficeX users pay for gas, the
                protocol fees auto-buy $OFX on decentralized exchanges. $OFX
                also offers utility in the form of &quot;gas-gated
                knowledge&quot;, DDoS protection &amp; governance voting power.
                Preseed buyers receive frozen $OFX to be unlocked at public
                token offering. $OFX is a preseed token, not a production token.
                Preseed $OFX is auto-exchanged for production $OFX on the
                release date of OfficeX v1.0-prod (currently v0.1-alpha). Read
                Tokenomics Offer Letter for full details. 
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element15">
              <h3 className="ofx-pre-seed-sale-header19">
                When does the Token Sale End?
              </h3>
              <p className="ofx-pre-seed-sale-content16">
                $OFX Preseed at $0.01 usd ends at $500k usd raised or earlier
                subject to momentum. Estimated end date is Oct 2024. 
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="product" className="ofx-pre-seed-sale-get-started">
        <div className="ofx-pre-seed-sale-header-container2">
          <div className="ofx-pre-seed-sale-header20">
            <h2 className="ofx-pre-seed-sale-heading13">
              Superior to Google Docs
            </h2>
            <p className="ofx-pre-seed-sale-caption12">
              OfficeX has everything your organization needs for work. A neutral
              replacement for Google &amp; Microsoft.
            </p>
          </div>
          <div className="ofx-pre-seed-sale-button2">
            <div className="ofx-pre-seed-sale-container23">
              <a
                href="https://drive.officex.app/?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=token_preseed_sale&amp;utm_term=launch_app&amp;utm_content=below_folder_superior_gdocs"
                target="_blank"
                rel="noreferrer noopener"
                className="ofx-pre-seed-sale-button3 button"
              >
                <span className="ofx-pre-seed-sale-text128">
                  Launch Free App
                </span>
              </a>
              <a href="#ico-hero" className="ofx-pre-seed-sale-button4 button">
                <span className="ofx-pre-seed-sale-text129">
                  Buy $OFX Preseed
                </span>
              </a>
            </div>
            <span className="ofx-pre-seed-sale-text130">
              Instant Access | No Signup Required
            </span>
          </div>
          <img
            alt="image"
            src="/group%20221-200h.png"
            className="ofx-pre-seed-sale-image12"
          />
        </div>
      </section>
      <section className="ofx-pre-seed-sale-slides">
        <div className="ofx-pre-seed-sale-slide-titles">
          <div className="ofx-pre-seed-sale-slide-title1 slide-title">
            <span className="ofx-pre-seed-sale-text131">Storage</span>
          </div>
          <div className="ofx-pre-seed-sale-slide-title2 slide-title slide-title-active">
            <span className="ofx-pre-seed-sale-text132">Documents</span>
          </div>
          <div className="ofx-pre-seed-sale-slide-title3 slide-title">
            <span className="ofx-pre-seed-sale-text133">Spreadsheets</span>
          </div>
          <div className="ofx-pre-seed-sale-slide-title4 slide-title">
            <span className="ofx-pre-seed-sale-text134">Presentations</span>
          </div>
        </div>
        <div className="ofx-pre-seed-sale-slider1 slider blaze-slider">
          <div className="ofx-pre-seed-sale-slider-container blaze-container">
            <div className="ofx-pre-seed-sale-slider-track-container blaze-track-container">
              <div className="ofx-pre-seed-sale-slider-track blaze-track">
                <div
                  data-role="current-banner"
                  className="ofx-pre-seed-sale-slide1 banner"
                >
                  <img
                    alt="image"
                    src="/ef4aba-min-400h.png"
                    className="ofx-pre-seed-sale-image13"
                  />
                  <div className="ofx-pre-seed-sale-content17">
                    <img
                      alt="image"
                      src="/group%20203-400h.png"
                      className="ofx-pre-seed-sale-image14"
                    />
                    <div className="ofx-pre-seed-sale-header21">
                      <h3 className="ofx-pre-seed-sale-heading14">Storage</h3>
                      <p className="ofx-pre-seed-sale-caption13">
                        Keep your files safe &amp; organized on your computer,
                        or shared on a secure decentralized cloud. Private file
                        storage.
                      </p>
                    </div>
                    <a
                      href="https://drive.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="ofx-pre-seed-sale-link19"
                    >
                      <div className="ofx-pre-seed-sale-more2">
                        <span className="ofx-pre-seed-sale-caption14">
                          <span className="ofx-pre-seed-sale-text135">
                            Launch App
                          </span>
                          <br></br>
                        </span>
                        <svg
                          viewBox="0 0 1024 1024"
                          className="ofx-pre-seed-sale-icon25"
                        >
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  data-role="current-banner"
                  className="ofx-pre-seed-sale-slide2 banner"
                >
                  <img
                    alt="image"
                    src="/9fa5-min-400h.png"
                    className="ofx-pre-seed-sale-image15"
                  />
                  <div className="ofx-pre-seed-sale-content18">
                    <img
                      alt="image"
                      src="/group%20204-400h.png"
                      className="ofx-pre-seed-sale-image16"
                    />
                    <div className="ofx-pre-seed-sale-header22">
                      <h3 className="ofx-pre-seed-sale-heading15">Documents</h3>
                      <p className="ofx-pre-seed-sale-caption15">
                        Write and edit documents with ease. No signup required,
                        realtime collaboration &amp; a professional author
                        experience.
                      </p>
                    </div>
                    <a
                      href="https://docs.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="ofx-pre-seed-sale-link20"
                    >
                      <div className="ofx-pre-seed-sale-more3">
                        <span className="ofx-pre-seed-sale-caption16">
                          Launch App
                        </span>
                        <svg
                          viewBox="0 0 1024 1024"
                          className="ofx-pre-seed-sale-icon27"
                        >
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  data-role="current-banner"
                  className="ofx-pre-seed-sale-slide3 banner"
                >
                  <img
                    alt="image"
                    src="/ded-21b8daac25e8-min-400h.png"
                    className="ofx-pre-seed-sale-image17"
                  />
                  <div className="ofx-pre-seed-sale-content19">
                    <img
                      alt="image"
                      src="/group%20205-400h.png"
                      className="ofx-pre-seed-sale-image18"
                    />
                    <div className="ofx-pre-seed-sale-header23">
                      <h3 className="ofx-pre-seed-sale-heading16">
                        Spreadsheets
                      </h3>
                      <p className="ofx-pre-seed-sale-caption17">
                        Calculate powerful models &amp; rich volume of data, on
                        a smooth infinite spreadsheet. Simple and expressive.
                      </p>
                    </div>
                    <a
                      href="https://sheets.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="ofx-pre-seed-sale-link21"
                    >
                      <div className="ofx-pre-seed-sale-more4">
                        <span className="ofx-pre-seed-sale-caption18">
                          Launch App
                        </span>
                        <svg
                          viewBox="0 0 1024 1024"
                          className="ofx-pre-seed-sale-icon29"
                        >
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  data-role="current-banner"
                  className="ofx-pre-seed-sale-slide4 banner"
                >
                  <img
                    alt="image"
                    src="/f431251-min-400h.png"
                    className="ofx-pre-seed-sale-image19"
                  />
                  <div className="ofx-pre-seed-sale-content20">
                    <img
                      alt="image"
                      src="/group%20206-400h.png"
                      className="ofx-pre-seed-sale-image20"
                    />
                    <div className="ofx-pre-seed-sale-header24">
                      <h3 className="ofx-pre-seed-sale-heading17">
                        Presentations
                      </h3>
                      <p className="ofx-pre-seed-sale-caption19">
                        Deliver rich presentations with confidence. Easy to
                        design, or clone from pre-built templates.
                      </p>
                    </div>
                    <a
                      href="https://decks.officex.app"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="ofx-pre-seed-sale-link22"
                    >
                      <div className="ofx-pre-seed-sale-more5">
                        <span className="ofx-pre-seed-sale-caption20">
                          Launch App
                        </span>
                        <svg
                          viewBox="0 0 1024 1024"
                          className="ofx-pre-seed-sale-icon31"
                        >
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-controls2">
              <button
                data-role="previous-banner-button"
                className="ofx-pre-seed-sale-previous2 blaze-prev button"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="ofx-pre-seed-sale-icon33"
                >
                  <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <button
                data-role="next-banner-button"
                className="ofx-pre-seed-sale-next2 blaze-next button"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="ofx-pre-seed-sale-icon35"
                >
                  <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="ofx-pre-seed-sale-container25">
            <Script
              html={`<script defer>
const el = document.querySelector('.blaze-slider')

new BlazeSlider(el, {
  all: {
    slidesToShow: 3,
    slideGap: '80px',
    loop: true,
    enableAutoplay: true,
  },
})
</script>`}
            ></Script>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-slider2">
        <div className="ofx-pre-seed-sale-header25">
          <h2 className="ofx-pre-seed-sale-heading18">
            <span>Manifesto </span>
            <span className="ofx-pre-seed-sale-text138">Freedom</span>
          </h2>
        </div>
        <div className="ofx-pre-seed-sale-selector">
          <p className="ofx-pre-seed-sale-caption21">
            <span className="ofx-pre-seed-sale-text139">
              OfficeX is an entrepreneurial status symbol.
            </span>
            <br className="ofx-pre-seed-sale-text140"></br>
            <br className="ofx-pre-seed-sale-text141"></br>
            <span className="ofx-pre-seed-sale-text142">Superior quality</span>
            <span className="ofx-pre-seed-sale-text143">
              {" "}
              at an unbeatable price - free unlimited forever. It&apos;s
              immortal software on the blockchain.
            </span>
            <br className="ofx-pre-seed-sale-text144"></br>
            <br className="ofx-pre-seed-sale-text145"></br>
            <span className="ofx-pre-seed-sale-text146">
              2 billion people pay $65B per year to Google &amp; Microsoft for
              workspace software but don&apos;t own anything. They can 
            </span>
            <span className="ofx-pre-seed-sale-text147">freeze your life</span>
            <span className="ofx-pre-seed-sale-text148">.</span>
            <br className="ofx-pre-seed-sale-text149"></br>
            <br className="ofx-pre-seed-sale-text150"></br>
            <span className="ofx-pre-seed-sale-text151">
              OfficeX is sovereign
            </span>
            <span className="ofx-pre-seed-sale-text152">
              {" "}
              because no one controls your digital life except you (guaranteed
              by cryptography). 
            </span>
            <span className="ofx-pre-seed-sale-text153">
              Every file shared is a fashion statement for freedom.
            </span>
            <br className="ofx-pre-seed-sale-text154"></br>
            <br className="ofx-pre-seed-sale-text155"></br>
            <span className="ofx-pre-seed-sale-text156">
              Join people who
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="ofx-pre-seed-sale-text157">
              forge their own path
            </span>
            <span className="ofx-pre-seed-sale-text158"> - </span>
            <span className="ofx-pre-seed-sale-text159">
              OfficeX was made for you.
            </span>
            <br className="ofx-pre-seed-sale-text160"></br>
            <br className="ofx-pre-seed-sale-text161"></br>
            <span className="ofx-pre-seed-sale-text162">Be</span>
            <span className="ofx-pre-seed-sale-text163"> Untouchable.</span>
            <br className="ofx-pre-seed-sale-text164"></br>
            <br className="ofx-pre-seed-sale-text165"></br>
            <span className="ofx-pre-seed-sale-text166">Terran</span>
            <br className="ofx-pre-seed-sale-text167"></br>
            <span className="ofx-pre-seed-sale-text168">Founder OfficeX</span>
            <br></br>
          </p>
        </div>
        <a
          href="https://bit.ly/officex-whitepaper"
          target="_blank"
          rel="noreferrer noopener"
          className="ofx-pre-seed-sale-link23"
        >
          <div className="ofx-pre-seed-sale-more6">
            <span className="ofx-pre-seed-sale-caption22">Read Whitepaper</span>
            <svg viewBox="0 0 1024 1024" className="ofx-pre-seed-sale-icon37">
              <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
        </a>
      </section>
      <section className="ofx-pre-seed-sale-statistics">
        <div className="ofx-pre-seed-sale-content21">
          <div className="ofx-pre-seed-sale-stat1">
            <h3 className="ofx-pre-seed-sale-header26">$1.69M</h3>
            <span className="ofx-pre-seed-sale-caption23">
              Consectetur adipiscing
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="ofx-pre-seed-sale-stat2">
            <h3 className="ofx-pre-seed-sale-header27">Multiverse</h3>
            <span className="ofx-pre-seed-sale-caption24">Unde omnis iste</span>
          </div>
          <div className="ofx-pre-seed-sale-stat3">
            <h3 className="ofx-pre-seed-sale-header28">500K</h3>
            <span className="ofx-pre-seed-sale-caption25">
              Accusantium doloremque
            </span>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-objectives">
        <div className="ofx-pre-seed-sale-content22">
          <span className="ofx-pre-seed-sale-text170">PRINCIPLES</span>
          <div className="ofx-pre-seed-sale-objectives-list">
            <div className="objective">
              <h3 className="ofx-pre-seed-sale-text171">Superior Quality</h3>
              <p className="ofx-pre-seed-sale-text172">
                Works offline, no download &amp; fully customizeable. Smoother,
                faster, easier than traditional options.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="objective ofx-pre-seed-sale-objective2">
              <h3 className="ofx-pre-seed-sale-text173">
                Free Unlimited Enterprise
              </h3>
              <p className="ofx-pre-seed-sale-text174">
                All features available with no restrictions, unlimited accounts
                &amp; instant setup. Free Forever.
              </p>
            </div>
            <div className="objective ofx-pre-seed-sale-objective3">
              <h3 className="ofx-pre-seed-sale-text175">100% Sovereign</h3>
              <p className="ofx-pre-seed-sale-text176">
                Neutral private software owned by you, immune to censorship
                &amp; bans. Available to anyone anywhere.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-customer2">
        <div className="ofx-pre-seed-sale-header29">
          <h2 className="ofx-pre-seed-sale-heading19">
            <span>Sovereign is </span>
            <span className="ofx-pre-seed-sale-text178">Sexy</span>
            <br></br>
          </h2>
          <span className="ofx-pre-seed-sale-text180">
            OfficeX is loved by Untouchable Entrepreneurs
          </span>
          <div className="ofx-pre-seed-sale-container26">
            <div className="ofx-pre-seed-sale-container27">
              <div className="ofx-pre-seed-sale-quote16">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_29263C8D-B932-4BF4-BC89-4F865D983867%20(2).mp4?alt=media&amp;token=6e657465-77d6-4057-bdeb-6359ee25a736"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FScreenshot%202024-08-13%20at%201.23.14%E2%80%AFAM-min.png?alt=media&amp;token=aaa5aaf4-1e4d-49b5-9210-5693118461eb"
                  preload="auto"
                  autoPlay="true"
                  playsInline="true"
                  className="ofx-pre-seed-sale-video17"
                ></video>
                <div className="ofx-pre-seed-sale-author16">
                  <span className="ofx-pre-seed-sale-name16">
                    @abundancestation
                  </span>
                  <span className="ofx-pre-seed-sale-location16">
                    322k Instagram
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container28">
              <div className="ofx-pre-seed-sale-quote17">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_46BCCB11-2BEF-4920-B20D-60EC04ECD714.MOV?alt=media&amp;token=736c2de2-368a-439e-996f-094559d05f4f"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8551%20(1)-min.jpg?alt=media&amp;token=64a40dff-12e4-4019-af82-b31f81def319"
                  preload="auto"
                  className="ofx-pre-seed-sale-video18"
                ></video>
                <div className="ofx-pre-seed-sale-author17">
                  <span className="ofx-pre-seed-sale-name17">
                    @overwealthacademy
                  </span>
                  <span className="ofx-pre-seed-sale-location17">
                    68k Instagram
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container29">
              <div className="ofx-pre-seed-sale-quote18">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2FIMG_8469.mp4?alt=media&amp;token=a7c439cc-6608-4734-b8b3-26b4ee2abda5"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FScreenshot%202024-08-13%20at%203.01.45%E2%80%AFAM-min.png?alt=media&amp;token=f3bd3bd4-02d3-4c1d-b497-6ecb6fa3d3ca"
                  className="ofx-pre-seed-sale-video19"
                ></video>
                <div className="ofx-pre-seed-sale-author18">
                  <span className="ofx-pre-seed-sale-name18">@valuance</span>
                  <span className="ofx-pre-seed-sale-location18">
                    184k Instagram
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container30">
              <div className="ofx-pre-seed-sale-quote19">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_7E4CDCED-5FC0-4F1A-B3ED-A9FDAAAB8A68%202.mp4?alt=media&amp;token=9b2a1d19-1925-43d1-b939-96594e7cc957"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8554%20(1)-min.jpg?alt=media&amp;token=c5d93cee-b79a-4666-9d3a-5542c833e564"
                  className="ofx-pre-seed-sale-video20"
                ></video>
                <div className="ofx-pre-seed-sale-author19">
                  <span className="ofx-pre-seed-sale-name19">@motigentt</span>
                  <span className="ofx-pre-seed-sale-location19">
                    76k Instagram
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container31">
              <div className="ofx-pre-seed-sale-quote20">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2F-9037149440928443275.MP4?alt=media&amp;token=452e6182-716f-4a5a-8d9e-725bfe6b1027"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8550%20(1)-min.jpg?alt=media&amp;token=e83ee981-e85d-476a-a026-48efffb1b930"
                  className="ofx-pre-seed-sale-video21"
                ></video>
                <div className="ofx-pre-seed-sale-author20">
                  <span className="ofx-pre-seed-sale-name20">@aetherium10</span>
                  <span className="ofx-pre-seed-sale-location20">
                    47k Instagram
                  </span>
                </div>
              </div>
            </div>
            <div className="ofx-pre-seed-sale-container32">
              <div className="ofx-pre-seed-sale-quote21">
                <video
                  src="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fcopy_B3B8CA23-E0D0-4022-BBD7-1E486730F059%20(1).MOV?alt=media&amp;token=1cae5b05-e689-439d-b883-1bf58953aaeb"
                  loop="true"
                  muted="true"
                  poster="https://firebasestorage.googleapis.com/v0/b/arbitrage-bot-ea10c.appspot.com/o/officex%2Fscreenshots%2FIMG_8555%20(1)-min.jpg?alt=media&amp;token=32bea1ca-3098-40f0-914c-d2d9f80acebf"
                  className="ofx-pre-seed-sale-video22"
                ></video>
                <div className="ofx-pre-seed-sale-author21">
                  <span className="ofx-pre-seed-sale-name21">
                    @wifiequalsmoney
                  </span>
                  <span className="ofx-pre-seed-sale-location21">
                    45k Instagram
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ofx-pre-seed-sale-controls3">
          <button className="ofx-pre-seed-sale-previous3 button">
            <svg viewBox="0 0 1024 1024" className="ofx-pre-seed-sale-icon39">
              <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
          </button>
          <button className="ofx-pre-seed-sale-next3 button">
            <svg viewBox="0 0 1024 1024" className="ofx-pre-seed-sale-icon41">
              <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </button>
          <button className="ofx-pre-seed-sale-button5 button">
            <span>
              <span>View all cases</span>
              <br></br>
            </span>
          </button>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-growth">
        <div className="ofx-pre-seed-sale-content23">
          <div className="ofx-pre-seed-sale-header30">
            <div className="ofx-pre-seed-sale-header31">
              <h2 className="ofx-pre-seed-sale-heading20">Full Features</h2>
            </div>
            <p className="ofx-pre-seed-sale-caption26">
              All features with unlimited use, free forever
            </p>
            <div className="ofx-pre-seed-sale-checkmarks1">
              <div className="ofx-pre-seed-sale-check10">
                <div className="ofx-pre-seed-sale-mark10">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon43"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text184">
                  All Features Available
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check11">
                <div className="ofx-pre-seed-sale-mark11">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon45"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text185">
                  Private &amp; Secure
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check12">
                <div className="ofx-pre-seed-sale-mark12">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon47"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text186">
                  100% Free Forever
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check13">
                <div className="ofx-pre-seed-sale-mark13">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon49"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text187">
                  Cloud starting at $0.01 / month
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check14">
                <div className="ofx-pre-seed-sale-mark14">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon51"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text188">
                  Still works during Apocalypse
                </span>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-testimonial1">
            <div className="ofx-pre-seed-sale-content24">
              <span className="ofx-pre-seed-sale-text189">MISSION</span>
              <p className="ofx-pre-seed-sale-text190">
                <span>“E</span>
                <span>
                  ssential digital tools accessible to all, helping solve global
                  challenges and sustainable development.
                </span>
                <span>”</span>
              </p>
            </div>
            <div className="ofx-pre-seed-sale-information1">
              <div className="ofx-pre-seed-sale-author22">
                <img
                  alt="image"
                  src="/un-logo-200h.png"
                  className="ofx-pre-seed-sale-icon53"
                />
                <span className="ofx-pre-seed-sale-name22">
                  <span>United Nations </span>
                  <br></br>
                  <span>Digital Public Goods Alliance</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="ofx-pre-seed-sale-images1">
          <div className="ofx-pre-seed-sale-square1"></div>
          <img
            alt="image"
            src="/toyota-zpu-1400w.jpg"
            className="ofx-pre-seed-sale-image21"
          />
        </div>
      </section>
      <section className="ofx-pre-seed-sale-experience">
        <div className="ofx-pre-seed-sale-images2">
          <div className="ofx-pre-seed-sale-square2"></div>
          <img
            alt="image"
            src="/image%20112%20(1)-min-1400w.png"
            className="ofx-pre-seed-sale-image22"
          />
        </div>
        <div className="ofx-pre-seed-sale-content25">
          <div className="ofx-pre-seed-sale-header32">
            <div className="ofx-pre-seed-sale-header-container3">
              <div className="ofx-pre-seed-sale-header33">
                <h2 className="ofx-pre-seed-sale-heading21">
                  Unlimited Enterprise Accounts
                </h2>
              </div>
              <p className="ofx-pre-seed-sale-caption27">
                Scaleable to billions of people, instant setup
              </p>
            </div>
            <div className="ofx-pre-seed-sale-checkmarks2">
              <div className="ofx-pre-seed-sale-check15">
                <div className="ofx-pre-seed-sale-mark15">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon54"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text197">
                  Free Unlimited Accounts
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check16">
                <div className="ofx-pre-seed-sale-mark16">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon56"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text198">
                  Comprehensive Admin Controls
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check17">
                <div className="ofx-pre-seed-sale-mark17">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon58"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text199">
                  Zero-Config Trustless Cloud
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check18">
                <div className="ofx-pre-seed-sale-mark18">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon60"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text200">
                  End to End Encryption
                </span>
              </div>
              <div className="ofx-pre-seed-sale-check19">
                <div className="ofx-pre-seed-sale-mark19">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="ofx-pre-seed-sale-icon62"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="ofx-pre-seed-sale-text201">
                  Open Source Whitelabel
                </span>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-testimonial2">
            <div className="ofx-pre-seed-sale-content26">
              <span className="ofx-pre-seed-sale-text202">MISSION</span>
              <p className="ofx-pre-seed-sale-text203">
                “Digital sovereignty means independence from foreign tech
                companies, starting with tools for collaboration and
                productivity.”
              </p>
            </div>
            <div className="ofx-pre-seed-sale-information2">
              <div className="ofx-pre-seed-sale-author23">
                <img
                  alt="image"
                  src="/mask%20group%20(14)-200h.png"
                  className="ofx-pre-seed-sale-icon64"
                />
                <span className="ofx-pre-seed-sale-name23">
                  <span>
                    European Union
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <br></br>
                  <span>Sovereign Workspace Initiative</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-create1">
        <div className="ofx-pre-seed-sale-content27">
          <div className="ofx-pre-seed-sale-header34">
            <h2 className="ofx-pre-seed-sale-heading22">
              <span>Join OfficeX</span>
              <br></br>
            </h2>
          </div>
          <span className="ofx-pre-seed-sale-text209">Where Freedom Works</span>
          <div className="ofx-pre-seed-sale-container33">
            <a
              href="https://drive.officex.app/?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=token_preseed_sale&amp;utm_term=launch_app&amp;utm_content=middle_cta_join_officex"
              target="_blank"
              rel="noreferrer noopener"
              className="ofx-pre-seed-sale-link24 button"
            >
              <span className="ofx-pre-seed-sale-text210">
                <span>Launch Free App</span>
                <br></br>
              </span>
            </a>
            <a href="#ico-hero" className="ofx-pre-seed-sale-link25 button">
              <span className="ofx-pre-seed-sale-text213">
                <span>Buy $OFX Preseed</span>
                <br></br>
              </span>
            </a>
          </div>
          <span className="ofx-pre-seed-sale-text216">
            Instant Access | No Signup Required
          </span>
        </div>
      </section>
      <section id="pricing" className="ofx-pre-seed-sale-comparision">
        <div className="ofx-pre-seed-sale-header-container4">
          <div className="ofx-pre-seed-sale-header35">
            <h2 className="ofx-pre-seed-sale-heading23">
              <span>OfficeX Compared</span>
              <br></br>
            </h2>
          </div>
          <p className="ofx-pre-seed-sale-caption28">
            Humanity Friendly Pricing
          </p>
        </div>
        <div className="ofx-pre-seed-sale-table">
          <div className="ofx-pre-seed-sale-row10">
            <div className="ofx-pre-seed-sale-headers10">
              <span className="ofx-pre-seed-sale-text219">Placeholder</span>
            </div>
            <div className="ofx-pre-seed-sale-plans10">
              <div className="ofx-pre-seed-sale-row11">
                <span className="ofx-pre-seed-sale-text220">OFFICEX</span>
                <span className="ofx-pre-seed-sale-text221">
                  GOOGLE WORKSPACE
                </span>
                <span className="ofx-pre-seed-sale-text222">
                  MICROSOFT OFFICE
                </span>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row12">
            <div className="ofx-pre-seed-sale-headers11">
              <span className="ofx-pre-seed-sale-text223">
                Documents, Spreadsheets &amp; Presentations
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans11">
              <div className="ofx-pre-seed-sale-row13">
                <div className="value">
                  <Check rootClassName="checkroot-class-name20"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name21"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name22"></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row14">
            <div className="ofx-pre-seed-sale-headers12">
              <span className="ofx-pre-seed-sale-text224">
                Cloud Storage &amp; Sharing
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans12">
              <div className="ofx-pre-seed-sale-row15">
                <div className="value">
                  <Check rootClassName="checkroot-class-name23"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name24"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name25"></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row16">
            <div className="ofx-pre-seed-sale-headers13">
              <span className="ofx-pre-seed-sale-text225">Free Accounts</span>
            </div>
            <div className="ofx-pre-seed-sale-plans13">
              <div className="ofx-pre-seed-sale-row17">
                <div className="value">
                  <span className="ofx-pre-seed-sale-text226">Unlimited</span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text227">Limit 3</span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text228">Limit 3</span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row18">
            <div className="ofx-pre-seed-sale-headers14">
              <span className="ofx-pre-seed-sale-text229">
                <span>
                  x1 Premium Account / Students
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="ofx-pre-seed-sale-text231">Save 99%</span>
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans14">
              <div className="ofx-pre-seed-sale-row19">
                <div className="value">
                  <span className="ofx-pre-seed-sale-text232">
                    ~$0.01 usd gas
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text233">$144 / year</span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text234">$72 / year</span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row20">
            <div className="ofx-pre-seed-sale-headers15">
              <span className="ofx-pre-seed-sale-text235">
                <span>
                  x10 Accounts / Small Business
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="ofx-pre-seed-sale-text237">Save 99%</span>
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans15">
              <div className="ofx-pre-seed-sale-row21">
                <div className="value">
                  <span className="ofx-pre-seed-sale-text238">
                    ~$10 gas/year
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text239">
                    $1440 / year
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text240">$720 / year</span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row22">
            <div className="ofx-pre-seed-sale-headers16">
              <span className="ofx-pre-seed-sale-text241">
                <span>
                  x100 Accounts / Agencies
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="ofx-pre-seed-sale-text243">Save 99%</span>
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans16">
              <div className="ofx-pre-seed-sale-row23">
                <div className="value">
                  <span className="ofx-pre-seed-sale-text244">
                    ~$100 gas/year
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text245">
                    $14,440 / year
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text246">
                    $7200 / year
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row24">
            <div className="ofx-pre-seed-sale-headers17">
              <span className="ofx-pre-seed-sale-text247">
                Enterprise &amp; Government Integration
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans17">
              <div className="ofx-pre-seed-sale-row25">
                <div className="value">
                  <span className="ofx-pre-seed-sale-text248">
                    Free &amp; Instant
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text249">
                    Starts at $500k
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text250">
                    Starts at $500k
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row26">
            <div className="ofx-pre-seed-sale-headers18">
              <span className="ofx-pre-seed-sale-text251">
                Developer Platform
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans18">
              <div className="ofx-pre-seed-sale-row27">
                <div className="value">
                  <Check rootClassName="checkroot-class-name26"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name27"></Check>
                </div>
                <div className="value">
                  <Check rootClassName="checkroot-class-name28"></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row28">
            <div className="ofx-pre-seed-sale-headers19">
              <span className="ofx-pre-seed-sale-text252">
                Works Offline (no internet)
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans19">
              <div className="ofx-pre-seed-sale-row29">
                <div className="value">
                  <Check rootClassName="checkroot-class-name29"></Check>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text253">
                    Limited Functionality
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text254">
                    Download Required
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row30">
            <div className="ofx-pre-seed-sale-headers20">
              <span className="ofx-pre-seed-sale-text255">
                Sovereign Rights / Own Your Data
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans20">
              <div className="ofx-pre-seed-sale-row31">
                <div className="value">
                  <Check rootClassName="checkroot-class-name30"></Check>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text256">
                    Foreign Owned
                  </span>
                </div>
                <div className="value">
                  <span className="ofx-pre-seed-sale-text257">
                    Foreign Owned
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row32">
            <div className="ofx-pre-seed-sale-headers21">
              <span className="ofx-pre-seed-sale-text258">
                Total Privacy / E2E Encryption
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans21">
              <div className="ofx-pre-seed-sale-row33">
                <div className="value">
                  <Check rootClassName="checkroot-class-name31"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name16"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name17"></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row34">
            <div className="ofx-pre-seed-sale-headers22">
              <span className="ofx-pre-seed-sale-text259">
                Free Speech / Censorship-Resistent
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans22">
              <div className="ofx-pre-seed-sale-row35">
                <div className="value">
                  <Check rootClassName="checkroot-class-name32"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name18"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name19"></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row36">
            <div className="ofx-pre-seed-sale-headers23">
              <span className="ofx-pre-seed-sale-text260">
                Open Source / Whitelabel
              </span>
            </div>
            <div className="ofx-pre-seed-sale-plans23">
              <div className="ofx-pre-seed-sale-row37">
                <div className="value">
                  <Check rootClassName="checkroot-class-name33"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name20"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name21"></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-row38">
            <div className="ofx-pre-seed-sale-headers24">
              <span className="ofx-pre-seed-sale-text261">Sexy / Software</span>
            </div>
            <div className="ofx-pre-seed-sale-plans24">
              <div className="ofx-pre-seed-sale-row39">
                <div className="value">
                  <Check rootClassName="checkroot-class-name34"></Check>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name22"></Line>
                </div>
                <div className="value">
                  <Line rootClassName="lineroot-class-name23"></Line>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-data">
        <div className="ofx-pre-seed-sale-header-container5">
          <div className="ofx-pre-seed-sale-header36">
            <div className="ofx-pre-seed-sale-row40">
              <h2 className="ofx-pre-seed-sale-heading24">
                <span>What makes OfficeX Invincible?</span>
                <br></br>
              </h2>
            </div>
          </div>
          <p className="ofx-pre-seed-sale-caption29">
            <span>Autonomous software running on the World Computer. </span>
            <span>Click below to learn more</span>
            <br></br>
          </p>
        </div>
        <div className="ofx-pre-seed-sale-content28">
          <div className="ofx-pre-seed-sale-image23">
            <img
              alt="image"
              src="/hd-wallpaper-satellite-eartth-space-1400w.jpg"
              className="ofx-pre-seed-sale-image24"
            />
          </div>
          <div className="ofx-pre-seed-sale-accordion">
            <div
              data-role="accordion-container"
              className="ofx-pre-seed-sale-element16 accordion accordion-active"
            >
              <div>
                <h3 className="ofx-pre-seed-sale-header37">100% Ownership</h3>
                <p
                  data-role="accordion-content"
                  className="ofx-pre-seed-sale-content30 accordion-active accordion-content"
                >
                  You own everything you create. No one has access to your files
                  or controls what you do with them. Modify the software however
                  you want. No paid subscriptions - Free Forever.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name4"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="ofx-pre-seed-sale-header38">
                  Trustless Blockchain
                </h3>
                <p
                  data-role="accordion-content"
                  className="ofx-pre-seed-sale-content32 accordion-active accordion-content"
                >
                  Upload &amp; Forget to a truly neutral cloud. Where autonomous
                  software runs at infinite scale with global availability &amp;
                  gas metered pricing. Zero developer trust or maintenance
                  required. Forever available to humanity.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name5"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="ofx-pre-seed-sale-header39">
                  Censorship Resistent
                </h3>
                <p
                  data-role="accordion-content"
                  className="ofx-pre-seed-sale-content34 accordion-active accordion-content"
                >
                  Free speech guaranteed by design. OfficeX survives internet
                  shutdowns using offline peer-to-peer collaboration. Censorship
                  resistant thanks to a permissionless blockchain where endless
                  private copies can be made. Enshrining our human rights of
                  collaboration.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name6"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="ofx-pre-seed-sale-header40">
                  Privacy Guaranteed
                </h3>
                <p
                  data-role="accordion-content"
                  className="ofx-pre-seed-sale-content36 accordion-active accordion-content"
                >
                  End-to-End Encryption offers maximum privacy. Only you can
                  decide who has access. Not even the government or OfficeX
                  developers can break in. Cryptographically guaranteed by magic
                  math.
                </p>
              </div>
              <IconContainer rootClassName="icon-containerroot-class-name7"></IconContainer>
            </div>
            <div>
              <div className="ofx-pre-seed-sale-container35">
                <Script
                  html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
                ></Script>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className="ofx-pre-seed-sale-faq2">
        <div className="ofx-pre-seed-sale-header41">
          <h2 className="ofx-pre-seed-sale-heading25">
            <span>Frequently asked </span>
            <span className="ofx-pre-seed-sale-text268">questions</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </h2>
        </div>
        <div className="ofx-pre-seed-sale-content37">
          <div className="ofx-pre-seed-sale-column3">
            <div className="ofx-pre-seed-sale-element20">
              <h3 className="ofx-pre-seed-sale-header42">
                How can it be free forever?
              </h3>
              <p className="ofx-pre-seed-sale-content38">
                OfficeX was designed to run as a standalone webapp without
                internet connection. Since we have zero server costs, the app
                can be 100% free forever. However, storing files on the cloud
                for sharing will cost ~$0.01 in gas fees (see gas faq below)
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element21">
              <h3 className="ofx-pre-seed-sale-header43">
                How does Cloud Pricing work? What is Gas?
              </h3>
              <p className="ofx-pre-seed-sale-content39">
                Gas is the $0.01 USD you pay for connecting to the blockchain
                internet. With gas, you only pay for your tiny share of cloud
                consumption - aka usage based pricing. Gas is 100x cheaper than
                paying monthly software subscriptions.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element22">
              <h3 className="ofx-pre-seed-sale-header44">
                How is OfficeX &quot;Apocalypse-Proof&quot;?
              </h3>
              <p className="ofx-pre-seed-sale-content40">
                OfficeX is &quot;apocalypse-proof&quot; because you can still
                use it if civilization collapsed (no internet, no google, no
                microsoft). Available Offline, No Signup Required.
              </p>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-column4">
            <div className="ofx-pre-seed-sale-element23">
              <h3 className="ofx-pre-seed-sale-header45">
                Why would rebels &amp; governments use OfficeX?
              </h3>
              <p className="ofx-pre-seed-sale-content41">
                Rebels &amp; Governments both need office software with serious
                sovereign protections. They often have budget constraints and
                fear leaking sensitive documents to foreign tech companies.
                OfficeX is a neutral alternative, running on a secure neutral
                cloud, with zero setup costs. It is extremely convenient and
                scales with the mission. 
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element24">
              <h3 className="ofx-pre-seed-sale-header46">
                Why does it need Blockchain?
              </h3>
              <p className="ofx-pre-seed-sale-content42">
                OfficeX uses blockchain technology because it simplifies
                document sharing, security &amp; servers. Blockchain also makes
                payments easier by accepting crypto.
              </p>
            </div>
            <div className="ofx-pre-seed-sale-element25">
              <h3 className="ofx-pre-seed-sale-header47">Is there a token?</h3>
              <p className="ofx-pre-seed-sale-content43">
                Yes, OfficeX offers $OFX as its utility &amp; governance token.
                See the Whitepaper for tokenomics &amp; roadmap. Not financial
                advice. Airdrops scheduled.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ofx-pre-seed-sale-create2">
        <div className="ofx-pre-seed-sale-content44">
          <div className="ofx-pre-seed-sale-header48">
            <h2 className="ofx-pre-seed-sale-heading26">
              <span>Join OfficeX</span>
              <br></br>
            </h2>
          </div>
          <span className="ofx-pre-seed-sale-text272">Where Freedom Works</span>
          <div className="ofx-pre-seed-sale-container36">
            <a
              href="https://drive.officex.app/?utm_source=homepage&amp;utm_medium=web&amp;utm_campaign=token_preseed_sale&amp;utm_term=launch_app&amp;utm_content=end_cta_join_officex"
              target="_blank"
              rel="noreferrer noopener"
              className="ofx-pre-seed-sale-link26 button"
            >
              <span className="ofx-pre-seed-sale-text273">
                <span>Launch Free App</span>
                <br></br>
              </span>
            </a>
            <a href="#ico-hero" className="ofx-pre-seed-sale-link27 button">
              <span className="ofx-pre-seed-sale-text276">
                <span>Buy $OFX Preseed</span>
                <br></br>
              </span>
            </a>
          </div>
          <span className="ofx-pre-seed-sale-text279">
            Instant Access | No Signup Required
          </span>
        </div>
      </section>
      <div className="ofx-pre-seed-sale-footer">
        <div className="ofx-pre-seed-sale-content45">
          <div className="ofx-pre-seed-sale-main">
            <div className="ofx-pre-seed-sale-branding2">
              <img
                alt="image"
                src="/officex%20(3)-200h.png"
                className="ofx-pre-seed-sale-image25"
              />
              <span className="ofx-pre-seed-sale-text280">
                <span>Free Unlimited Workspace.</span>
                <br></br>
                <span>
                  The Superior Clone of Google Docs.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br></br>
                <span>Where Freedom Works.</span>
                <br></br>
                <br></br>
                <span>admin@officex.app</span>
                <br></br>
              </span>
            </div>
            <div className="ofx-pre-seed-sale-links">
              <div className="ofx-pre-seed-sale-column5">
                <span className="ofx-pre-seed-sale-header49">Company</span>
                <div className="ofx-pre-seed-sale-list1">
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link28"
                  >
                    About
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link29"
                  >
                    Whitepaper
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link30"
                  >
                    Tokenomics
                  </a>
                  <a
                    href="https://bit.ly/officex-whitepaper"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link31"
                  >
                    <span>Community</span>
                    <br></br>
                  </a>
                  <a
                    href="https://github.com/officex"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link32"
                  >
                    <span>Github</span>
                    <br></br>
                  </a>
                  <a
                    href="https://bit.ly/officex-tos"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link33"
                  >
                    Terms
                  </a>
                </div>
              </div>
              <div className="ofx-pre-seed-sale-column6">
                <span className="ofx-pre-seed-sale-header50">Social</span>
                <div className="ofx-pre-seed-sale-list2">
                  <a
                    href="https://x.com/officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link34"
                  >
                    Twitter X
                  </a>
                  <a
                    href="https://www.tiktok.com/@officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link35"
                  >
                    TikTok
                  </a>
                  <a
                    href="https://www.instagram.com/officexapp/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link36"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.youtube.com/@OfficeXApp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link37"
                  >
                    YouTube
                  </a>
                  <a
                    href="https://www.linkedin.com/in/terran-principal-a94163323/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link38"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="https://medium.com/@officex"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link39"
                  >
                    Medium
                  </a>
                  <a
                    href="https://t.me/officexapp"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link40"
                  >
                    Telegram
                  </a>
                  <a
                    href="https://bit.ly/officex-discord"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ofx-pre-seed-sale-link41"
                  >
                    Discord
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="ofx-pre-seed-sale-bottom">
            <span className="ofx-pre-seed-sale-text295">
              © 2024 OfficeX - All rights reserved
            </span>
            <button
              data-role="scroll-top"
              className="ofx-pre-seed-sale-button6 button"
            >
              <img
                alt="image"
                src="/arrow.svg"
                className="ofx-pre-seed-sale-image26"
              />
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="ofx-pre-seed-sale-container38">
          <Script
            html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  );
};

export default OFXPreSeedSale;
